<template>
    <div class="content">
        <div  id="login_container" class="wx_box" >
            <div class="wx_tit">扫码登录</div>
            <img src="../assets/icon_code.png" class="wx_code">
            <p class="wx_im">请用微信扫码登录</p>
          </div>
    </div>
</template>

<script>
    import {getAppID} from '@/api/user.js'
    import keys from '@/utils/keys.js'
    import {
		setSessionData
	  } from '@/utils/session'
  export default{
        name:'Login',

        mounted() {
            // this.getAppID();
        },
        methods: {
            
            getAppID(){
                getAppID().then(res=>{
                    var data = res.data;

                    // let state = parseInt(new Date().getTime() / 1000)
                    //  var obj = new WxLogin({
                    //       self_redirect:true,
                    //       id:"login_container", 
                    //       appid: data.appid, 
                    //       scope: "snsapi_login", 
                    //       redirect_uri: data.redirect_url,
                    //       state: "",
                    //       style: state,
                    //       href: ""
                    //     });
                        setSessionData( window.location.href,keys.BEFORELOFINHREF) // 记录当前操作页面,以备后面跳转
                     console.log(data);
                })
            }
        },



    }
</script>

<style scoped lang="less">
@import "../common/public.less";

.content{

    width: 400px;
    height: 320px;

    .wx_box{
    width: 100%;
    height: 100%;
    padding: 30px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 5px #919191;
    border-radius: 5px;
    .wx_tit{
      font-size: 20px;
      font-weight: bold;
    }
    .wx_code{
      width: 200px;
      height: 200px;
      margin: 30px 0 20px;
    }
    .wx_im{
      font-size: 16px;
      color: #777;
    }
  }
}


</style>