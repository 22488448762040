<template>
  <div>
    <top></top>
    <ul class="beauty_box">
      <li :key="index" v-for="(item,index) in beautyList" class="beauty_list" @click="beautyClick(index)">
        <img :src="item.cover" class="beauty_img">
        <div class="beauty_tit">{{item.title}}</div>
        <div class="beauty_info">{{item.info}}</div>
      </li>
    </ul>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import { getLive,getLiveDetail } from '@/api/lives'
export default {
  name: "Beauty",
  components: { Right, Bottom, Top },
  data() {
    return {
      beautyList:[
        
      ],
    }
  },
  mounted(){
    this.handleFetchGetLives()
  },
  methods:{
    handleFetchGetLives(){
      getLive({
        app_id: this.$store.state.appId,
      }).then(res=>{
        console.log(res)
        this.beautyList = res.data
      })
    },
    beautyClick(index){
      
      var brauty = this.beautyList[index];
      this.$router.push({
        name:'BeautyDetails',
        params:{
          id:brauty.id
        }
      })
    }
  }
};
</script>

<style lang="less">
 @import "../common/public.less";
 .beauty_box{
   width: 1200px;
   margin: 0 auto;
   min-height: 400px;
   .dis;
   flex-wrap: wrap;
   padding-top: 15px;
   .beauty_list{
     width: 285px;
     margin-right: 15px;
     text-align: center;
     margin-bottom: 45px;
     img{
       width: 100%;
       height: 285px;
     }
     .beauty_tit{
       font-size: 18px;
       padding: 15px 0;
       width: 250px;
       margin: 0 15px 15px;
       border-bottom: 1px solid #ececec;
       font-weight: bold;
     }
     .beauty_info{
       font-size: 14px;
       color: #999;
       padding: 0 15px;
       .overt;
     }
     &:nth-child(4n){
       margin-right: 0;
     }
   }
 }

</style>