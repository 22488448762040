import http from '../utils/request'


/**
 * 获取商品分类
 * @param {*} params 
 * @returns 
 */
export function getClass(params){
    return http.get('/pc/goods?m=getGoodsCateList',{params});
}



/**
 * 获取商品列表
 * @param {*} params 
 * @returns 
 */
export function getGood(params){
    return http.get('/pc/goods?m=getGoodsList',{params});
}


/**
 * 获取首页配置
 * @param {*} params 
 * @returns 
 */
export function getIndexPage(params){
    return http.get('/pc/mall?m=getPageInfo',{params});
}



/**
 * 获取店铺列表
 * @param {*} params 
 * @returns 
 */
export function getMallList(params){
    return http.get('/pc/mall?m=mallList',{params});
}


/**
 * 获取商品分类以及商品
 * @param {*} params 
 * @returns 
 */
export function getGoodsCateListTwo(params){
    return http.get('/pc/goods?m=getGoodsCateListTwo',{params});
}



/**
 * 获取商品分类
 * @param {*} params 
 * @returns 
 */
export function getGoodsCateList(params){
    return http.get('/pc/mall?m=getGoodsCateList',{params});
  }
  

/**
 * 获取店铺主页
 * @param {*} params 
 * @returns 
 */
export function getShopMainPage(params){
    return http.get('/pc/mall?m=mallShopDetail',{params});
  }
  
/**
 * 获取分类
 * @param {*} params 
 * @returns 
 */
export function getCateList(params){
    return http.get('/pc/mall?m=cateList',{params});
  }
  
/**
 * 点击量
 * @param {*} params 
 * @returns 
 */
export function shopView(params){
    return http.get('/pc/mall?m=shopView',{params});
  }
  