import http from '../utils/request'



/**
 * 获取直播列表
 * @param {*} params 
 * @returns 
 */
export function getLive(params){
  return http.get('/pc/mall?m=getLiveList',{params});
}



/**
 * 获取直播详情
 * @param {*} params 
 * @returns 
 */
export function getLiveDetail(params){
  return http.get('/pc/mall?m=getLiveDetail',{params});
}