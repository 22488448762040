<template>
  <div class="bot_z">
    <div class="bot_box">
      <ul class="bot_con">
        <li class="bot_link">
          <div class="link_tit">友情链接</div>
          <ul class="link_con">
            <li class="link_list">
              <a href="http://www.hefei.gov.cn" target="_blank">合肥政府网</a>
            </li>
            <li class="link_list">
              <a href="http://swj.hefei.gov.cn" target="_blank">合肥市商务局</a>
            </li>
            <li class="link_list">
              <a href="http://xczx.hefei.gov.cn" target="_blank"
                >合肥市乡村振兴局</a
              >
            </li>
            <li class="link_list">
              <a href="http://www.chaohu.gov.cn" target="_blank"
                >巢湖市人民政府</a
              >
            </li>
            <li class="link_list">
              <a href="http://www.hfbtv.com" target="_blank"
                >合肥市广播电视台</a
              >
            </li>
          </ul>
        </li>
        <li class="divider"></li>
        <li class="service_box">
          <div>
            <div class="service_tit">安徽合肥幸福路消费帮扶公共服务平台</div>
            <p class="service_list">客服电话：0551-82626107 或 15375250205</p>
            <p class="service_list">客服在线时间：8:30 - 19:00</p>
            <p class="service_list">电话咨询时间：工作日早8:30到晚17:30</p>
          </div>
          <div class="code_box">
            <img src="../assets/icon_code.jpg" />
            <p>扫码进入小程序</p>
          </div>
          <!-- <p class="service_list">休息日（节假日）：微信（19348234022）</p> -->
        </li>
      </ul>
      <div class="bot_record">
        <img src="../assets/icon_record.png" />
        <a href="https://beian.miit.gov.cn/"
          ><span style="color: #fff">皖ICP备2022010432号</span></a
        >
      </div>
      <div class="bot_record">
        <span>Copyrights © 2022-2024</span>
        <span style="margin-left: 8px"
          >合肥广视数字智能科技有限公司 提供技术支持</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
};
</script>

<style lang="less">
@import "../common/public.less";
.bot_z {
  background: #414141;
  color: #bbbab7;
  width: 100%;
  padding: 50px 0 20px !important;
  .bot_box {
    width: 1200px;
    margin: 0 auto;
    .divider {
      border-right: 1px solid #adadad;
      margin: 0 40px;
    }
    .bot_con {
      .dis;
      padding-bottom: 10px;
      .bot_link {
        flex: 1;
        // padding-right: 50px;
        // margin-right: 80px;
        .link_tit {
          font-size: 18px;
          color: #fff;
        }

        .link_con {
          .dis;
          flex-wrap: wrap;
          margin-top: 20px;
          .link_list {
            margin-right: 20px;
            margin-bottom: 20px;
            a {
              text-decoration: none;
              color: #adadad;
            }
            a:active {
              text-decoration: none;
            }
            a:link {
              text-decoration: none;
            }
          }
        }
      }
      .service_box {
        flex: 1;
        display: flex;
        .service_tit {
          font-size: 22px;
          color: #fff;
          width: 60%;
        }
        .service_list {
          padding-top: 15px;
        }
      }
      .code_box {
        // flex: 1;
        text-align: center;
        img {
          width: 150px;
          height: 150px;
          margin-bottom: 10px;
        }
      }
    }
    .bot_record {
      padding-top: 15px;
      font-size: 14px;
      text-align: center;
      img {
        width: 13px;
        height: 13px;
        margin-right: 10px;
      }
    }
  }
}
</style>
