<template>
  <ul class="right_box" ref="box">
    <li class="right_list">
      <img src="../assets/icon_code.jpg" />
      <div class="right_text">扫码进入小程序</div>
      <div class="qrcode">
        <img
          src="../assets/icon_code.jpg"
          style="width: 200px; height: 200px"
        />
      </div>
    </li>

    <li class="right_list">
      <img src="../assets/icon_service.png" />
      <div class="right_text">客服电话</div>
      <div class="right_text">0551-82626107</div>
    </li>
    <li class="right_list">
      <button @click="backTop" class="right_top">
        <img src="../assets/icon_top.png" />
        <div class="right_text">回顶部</div>
      </button>
    </li>
    <li class="right_list" v-show="showPrevious" @click="previousClick">
      <img src="../assets/icon_back.png" />
      <div class="right_text">返回上一页</div>
    </li>
  </ul>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  name: "Right",
  props: {
    showPrevious: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const btnShow = ref(false);
    let timer = null;
    onMounted(() => {
      window.addEventListener("scroll", scrollToTop);
    });
    function backTop() {
      timer = setInterval(function () {
        let backTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let speedTop = backTop / 5;
        document.documentElement.scrollTop = backTop - speedTop;
        if (backTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    }
    function scrollToTop() {
      let pageLookHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scroll = document.documentElement.scrollTop;

      if (scroll >= pageLookHeight) {
        btnShow.value = true;
      } else {
        btnShow.value = false;
      }
    }
    return {
      top,
      btnShow,
      timer,
      backTop,
      scrollToTop,
    };
  },
  methods: {
    previousClick() {
      this.$router.push({
        name: "Beauty",
      });
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
.right_box {
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 9;
  //background: #f7f7f7;
  padding: 0 20px;
  .right_list {
    width: 75px;
    font-size: 14px;
    color: #999;
    border-bottom: 1px solid #ececec;
    padding: 15px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:hover {
      .qrcode {
        opacity: 1;
      }
    }
    .qrcode {
      position: absolute;
      left: -240px;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      pointer-events: none;
      opacity: 0;
      transition: all 0.5s;
      z-index: 999;
      background: #fff;
      border-radius: 8px;
    }
    &:last-child {
      border-bottom: none;
    }
    .right_top {
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: #999;
    }
    img {
      width: 55px;
      height: 55px;
    }
  }
}
</style>
