<template>
  <div class="place_box">
    <div class="place_list">
      <router-link
        :to="{ name: 'productList', query: { cate_id: item.id } }"
        target="_blank"
        v-for="(item, index) in category.filter(item=> item.id==637 || item.id==657)"
        :key="index"
      >
        <img :src="item.image" class="place_img" />
      </router-link>
    </div>
    <div class="place_list">
      <router-link
        :to="{ name: 'productList', query: { cate_id: item.id } }"
        target="_blank"
        v-for="(item, index) in category.filter(item=> item.id==658 || item.id==648)"
        :key="index"
      >
        <img :src="item.image" class="place_img" />
      </router-link>
    </div>
    <div class="place_list">
      <router-link
        :to="{ name: 'productList', query: { cate_id: item.id } }"
        target="_blank"
        v-for="(item, index) in category.filter(item=> item.id==730 || item.id==660)"
        :key="index"
      >
        <img :src="item.image" class="place_img" />
      </router-link>
    </div>
    <div class="place_list">
      <router-link
        :to="{ name: 'productList', query: { cate_id: item.id } }"
        target="_blank"
        v-for="(item, index) in category.filter(item=> item.id==659 || item.id==700)"
        :key="index"
        style="width: 100%"
      >
        <img :src="item.image" class="place_img" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Place",
  props: {
    category: {
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
.place_box {
  .disaf;
  .place_img {
    width: 290px;
    height: 370px;
    margin-right: 10px;
  }
  .place_list {
    width: 300px;
    height: 370px;
    .dis;
    flex-wrap: wrap;
    align-content: space-between;
    // &:last-child {
    //   margin-left: 10px;
    // }
    img {
      width: 290px;
      height: 180px;
    }
  }
}
</style>