<template>
  <div>
    <top></top>
    <div class="common_box">
      <!-- <img :src="detail.shop_img" class="su_banner" /> -->
      <div class="store_box" style="margin-top: 30px">
        <div class="store_tit">
          <span>首页</span>
          <span>/</span>
          <span>{{ detail.name }}</span>
        </div>
        <ul class="store_con">
          <li class="store_fl">
            <ul>
              <!--选中加class store_ac-->
              <li
                v-for="(item, index) in cateList"
                :key="index"
                @click="handleCurrentChange(item)"
                :class="['store_list', currentSelect == item.id && 'store_ac']"
              >
                <span class="store_line"></span>
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </li>
          <li class="store_fr">
            <div>
              <goods :goodsList="goodsList" v-if="goodsList.length"></goods>
              <el-empty v-else style="margin: 80px 0" />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
              "
            >
              <el-pagination
                background
                :page-size="24"
                layout="prev, pager, next"
                :total="total"
                @current-change="handlePageChange"
              >
              </el-pagination>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "@/components/Top";
import Bottom from "@/components/Bottom";
import Right from "@/components/Right";
import Goods from "@/components/Goods";
import { getShopMainPage, getCateList, getGood } from "@/api/shop";
export default {
  name: "SuppliersDetails",
  components: { Goods, Right, Bottom, Top },
  data() {
    return {
      goodsList: [],
      cateList: [],
      detail: {},
      currentSelect: -1,
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.handleFetchShopMainPage();
    this.handleFetchCategory();
  },

  methods: {
    handleCurrentChange(item) {
      this.currentSelect = item.id;
      this.handleFetchGoodsList();
    },
    handlePageChange(current) {
      this.page = current;
      this.handleFetchGoodsList();
    },
    handleFetchGoodsList() {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      getGood({
        cate_id: this.currentSelect,
        page_idx: this.page,
        page_size: 12,
        app_id: this.$store.state.appId,
        shop_id: this.$route.query.id,
      })
        .then((res) => {
          console.log(res);
          this.goodsList = res.data;
          this.total = res.datas.count;
        })
        .finally(() => {
          loading.close();
        });
    },
    handleFetchShopMainPage() {
      getShopMainPage({
        app_id: this.$store.state.appId,
        shop_id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        this.detail = res.data;
      });
    },
    handleFetchCategory() {
      getCateList({
        app_id: this.$store.state.appId,
        // shop_id: this.$route.query.id,
      }).then((res) => {
        console.log(res);
        if (res.data.some((item) => item.shop_id != this.$route.query.id)) {
          this.cateList = [{ name: "全部分类" }, ...res.data.filter(item=>item.shop_id == this.$route.query.id)];
        } else {
          this.cateList = res.data;
        }

        this.currentSelect = this.cateList[0]?.id;
        this.handleFetchGoodsList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../common/public.less";

/deep/.goods_list:nth-child(4n) {
  margin-right: 12.3px !important;
}
/deep/.goods_list:nth-child(3n) {
  margin-right: 0px !important;
}
.su_banner {
  width: 100%;
  height: 320px;
  margin: 20px 0;
}
.store_box {
  .store_tit {
    color: #666;
    span {
      margin-right: 10px;
    }
  }
  .store_con {
    .disf;
    padding: 20px 0;
    .store_fl {
      width: 230px;
      border: 1px dashed #ececec;
      .store_list {
        padding: 20px 0;
        width: 100%;
        cursor: pointer;
        .disa;
        justify-content: center;
        border-bottom: 1px dashed #ececec;
        .store_line {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #d0d0d0;
          margin-right: 15px;
        }
      }
      .store_ac {
        color: #e1251b;
        .store_line {
          background: #e1251b;
        }
      }
    }
    .store_fr {
      width: 900px;
      border: 1px dashed #ececec;
      padding: 0 20px;
    }
  }
}
// .goods_box .goods_list:nth-child(3n) {
//   margin-right: 0;
// }
// .goods_box .goods_list:nth-child(4n) {
//   margin-right: 12.3px;
// }
</style>