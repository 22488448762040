import http from '../utils/request'


/**
 * 获取appid
 * @param {*} params 
 * @returns 
 */
export function getAppID() {
    return http.get('/api/getAppID')
}


/**
 * 登录
 * @param {*} params 
 * @returns 
 */
export function loginWithCode(code) {
    return http.get('/api/loginWithCode', { code });
}