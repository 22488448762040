<template>
  <div class="goods-image">
    <div
      v-if="!isOutside"
      class="large"
      :style="{
        backgroundImage: `url(${imageList[curIndex]})`,
        backgroundPositionX: positionX + 'px',
        backgroundPositionY: positionY + 'px',
      }"
    ></div>
    <div
      class="middle"
      ref="target"
      @mouseenter="handleMouseEnter"
      @mouseout="mouseout"
      @mousemove="mousemove"
    >
      <img :src="imageList[curIndex]" alt="" class="middle_img" />
      <img src="../assets/icon_amplification.png" class="middle_icon" />
      <!-- 蒙层容器 -->
      <div
        class="layer"
        :style="{ left: left + 'px', top: top + 'px' }"
        v-if="!isOutside"
      ></div>
    </div>
    <ul class="small">
      <li
        v-for="(img, i) in imageList"
        :key="i"
        @mouseenter="mouseenter(i)"
        :class="{ active: curIndex === i }"
      >
        <img :src="img" alt="" />
      </li>
    </ul>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useMouseInElement } from "@vueuse/core";

export default {
  name: "GoodsImage",
  props: {
    imageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // imageList: [
      //   require("../assets/banner2.png"),
      //   require("../assets/good_img.png"),
      //   require("../assets/banner1.png"),
      //   require("../assets/good_img.png"),
      //   require("../assets/banner2.png"),
      // ],
    };
  },
  setup() {
    const curIndex = ref(0);

    function mouseenter(index) {
      curIndex.value = index;
    }

    const target = ref(null);
    const elementX = ref(0);
    const elementY = ref(0);
    const isOutside = ref(true);
    function handleMouseEnter(e) {
      console.log(e.target);
      isOutside.value = false;
    }

    function mousemove(e) {
      // console.log(e.offsetX, e.offsetY)
      elementX.value = e.offsetX;
      elementY.value = e.offsetY;
    }
    function mouseout(e) {
      isOutside.value = true;
    }
    // const { elementX, elementY, isOutside,sourceType } = useMouseInElement(target);
    // 监听
    const left = ref(0);
    const top = ref(0);
    // 定位位置
    const positionX = ref(0);
    const positionY = ref(0);

    watch([elementX, elementY], () => {
      // 监听处理

      // 移动
      if (elementX.value <= 100) {
        left.value = 0;
      }
      if (elementX.value >= 350) {
        left.value = 250;
      }
      if (elementY.value <= 100) {
        top.value = 0;
      }
      if (elementY.value >= 350) {
        top.value = 250;
      }
      // 有效范围
      if (elementX.value >= 100 && elementX.value <= 350) {
        left.value = elementX.value - 100;
      }
      if (elementY.value >= 100 && elementY.value <= 350) {
        top.value = elementY.value - 100;
      }
      // 控制背景图：1.方向相反  2.比例1:2或者更大
      positionX.value = -left.value / (200 / 450);
      positionY.value = -top.value / (200 / 450);
      console.log(positionX.value);
      console.log(positionY.value);
    });

    return {
      handleMouseEnter,
      curIndex,
      mouseenter,
      target,
      left,
      top,
      isOutside,
      mouseout,
      mousemove,
      positionX,
      positionY,
    };
  },
};
</script>
<style scoped lang="less">
.goods-image {
  width: 450px;
  position: relative;
  // 大图盒子
  .large {
    position: absolute;
    top: 0;
    left: 460px;
    width: 450px;
    height: 450px;
    z-index: 500;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: calc(450px / (200 / 450)) calc(450px / (200 / 450));
    background-color: #f8f8f8;
  }

  .middle {
    width: 450px;
    height: 450px;
    background: #f5f5f5;
    position: relative;

    .middle_img {
      width: 450px;
      height: 450px;
    }

    .middle_icon {
      width: 30px;
      height: 30px;
      position: absolute;
      pointer-events: none;
      bottom: 0;
      right: 0;
    }
  }

  // 蒙层
  .layer {
    width: 200px;
    height: 200px;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
  }

  .small {
    width: 450px;
    display: flex;
    margin: 20px 0;

    li {
      width: 80px;
      height: 80px;
      margin-right: 12px;
      margin-bottom: 15px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.active {
        border: 2px solid #e1251b;
      }

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
</style>
