<template>
  <div>
    <top></top>
    <div class="common_box">
      <div>
        <div class="street_box" style="border-top: 1px dashed #dadada">
          <span class="street_label">街道:</span>
          <ul class="street_con">
            <li
              v-for="(item, index) in streetList"
              :key="index"
              :class="[
                'street_list',
                active.includes(item.label) ? 'street_active' : '',
              ]"
              @click="handleSelect(item.label, 1)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="street_box" style="border-top: 1px dashed #dadada">
          <span class="street_label">乡镇:</span>
          <ul class="street_con">
            <li
              v-for="(item, index) in townList"
              :key="index"
              :class="[
                'street_list',
                active.includes(item.label) ? 'street_active' : '',
              ]"
              @click="handleSelect(item.label, 2)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>

      <el-empty v-if="!supplierList.length" description="暂无数据"></el-empty>
      <!--列表-->
      <ul class="supplier_box">
        <li
          v-for="(item, index) in supplierList"
          :key="index"
          class="supplier_list"
        >
          <img
            :src="item.logo"
            class="supplier_icon"
            @click.stop="navigateToDetail(item)"
          />
          <div class="supplier_info" @click.stop="navigateToDetail(item)">
            <div class="info_tit">{{ item.name }}</div>
            <div class="info_address">{{ item.address }}</div>
            <div class="info_star">
              <div class="star_list">
                <img src="../assets/icon_star.png" />
                <img src="../assets/icon_star.png" />
                <img src="../assets/icon_star.png" />
                <img src="../assets/icon_star.png" />
                <img src="../assets/icon_star.png" />
              </div>
              <span>评分:{{ item.score || "5分" }}</span>
            </div>
            <div
              style="text-align: center; margin-top: 30px"
              v-if="item.extra_url"
            >
              <div style="font-size: 16px;text-align: left;" >{{item.extra_name}}</div>
              <button
                style="
                  background: rgb(255, 73, 23);
                  color: #fff;
                  width: 80px;
                  margin-top: 5px;
                  height: 30px;
                "
                @click.stop="navigateToTaoBao(item)"
              >
                进入店铺
              </button>
            </div>
          </div>
          <ul class="supplier_goods">
            <li
              class="sgoods_list"
              v-for="(commodity, index) in item.goods"
              :key="index"
              style="cursor: pointer"
              @click.stop="handleNavigateToDeatail(commodity)"
            >
              <img :src="commodity.goods_logo" class="sgoods_img" />
              <p class="sgoods_tit">{{ commodity.goods_title }}</p>
            </li>
          </ul>
        </li>
      </ul>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          margin-top: 20px;
        "
      >
        <el-pagination
          background
          :page-size="10"
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import { getMallList, shopView } from "@/api/shop";
export default {
  name: "Supplier",
  components: { Right, Bottom, Top },
  data() {
    return {
      active: [],
      total: 0,
      page: 1,
      streetList: [
        { label: "全部街道", value: "全部" },
        { label: "中庙街道", value: "中庙街道" },
        { label: "亚父街道", value: "亚父街道" },
        { label: "卧牛山街道", value: "卧牛山街道" },
        { label: "凤凰山街道", value: "凤凰山街道" },
        { label: "天河街道", value: "天河街道" },
        { label: "半汤街道", value: "半汤街道" },
      ],
      townList: [
        { label: "全部乡镇", value: "全部" },
        { label: "栏杆集镇", value: "栏杆集镇" },
        { label: "苏湾镇", value: "苏湾镇" },
        { label: "柘皋镇", value: "柘皋镇" },
        { label: "银屏镇", value: "银屏镇" },
        { label: "夏阁镇", value: "夏阁镇" },
        { label: "中垾镇", value: "中垾镇" },
        { label: "散兵镇", value: "散兵镇" },
        { label: "烔炀镇", value: "烔炀镇" },
        { label: "黄麓镇", value: "黄麓镇" },
        { label: "槐林镇", value: "槐林镇" },
        { label: "坝镇", value: "坝镇" },
        { label: "庙岗镇", value: "庙岗镇" },
      ],
      supplierList: [],
    };
  },
  created() {
    this.page = this.$route.query.page || 1;
    this.handleFetchMallList();
  },
  methods: {
    navigateToTaoBao(item) {
      if (item.extra_url) {
        window.open(item.extra_url);
      }
    },
    navigateToDetail(item) {
      shopView({
        app_id: this.$store.state.appId,
        shop_id: item.id,
      });
      this.$router.push({
        name: "SupplierDetail",
        query: {
          id: item.id,
        },
      });
    },
    handleSelect(e, type) {
      console.log(e);
      if (this.active.includes(e)) {
        if (e == "全部街道") {
          this.streetList.forEach((obj) => {
            this.active = this.active.filter((item) => item != obj.label);
          });
        } else if (e == "全部乡镇") {
          this.townList.forEach((obj) => {
            this.active = this.active.filter((item) => item != obj.label);
          });
        } else {
          this.active = this.active.filter((item) => item != e);
        }
      } else {
        if (e == "全部街道") {
          this.streetList.forEach((obj) => {
            if (!this.active.includes(obj.label)) {
              this.active = [...this.active, obj.label];
            }
          });
        } else if (e == "全部乡镇") {
          this.townList.forEach((obj) => {
            if (!this.active.includes(obj.label)) {
              this.active = [...this.active, obj.label];
            }
          });
        } else {
          this.active = [...this.active, e];
        }
      }
      console.log(this.active);
      this.handleFetchMallList();
    },
    handleNavigateToDeatail(goods) {
      this.$router.push({
        name: "GoodsDetails",
        params: {
          id: goods.id,
        },
      });
    },
    handleCurrentChange(current) {
      this.page = current;
      this.handleFetchMallList();
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.page,
        },
      });
    },
    handleFetchMallList() {
      var choose = this.active.filter((item) => {
        return item != "全部乡镇" && item != "全部街道";
      });
      console.log(choose);
      getMallList({
        app_id: this.$store.state.appId,
        street: choose.join(","),
        page_idx: this.page,
      }).then((res) => {
        console.log(res);
        this.supplierList = res.data;
        // this.total = res.datas.count;
      });
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
body {
  background: #f7f7f7;
}
.top_box {
  background: #fff;
  border-bottom: 1px solid #ececec;
}
.common_box {
  padding: 0 40px;
  background: #fff;
  margin-bottom: 60px;
}
.street_box {
  .disaf;
  background: #f5f5f5;
  padding: 20px 0;
  font-size: 14px;
  .street_label {
    display: inline-block;
    width: 80px;
    text-align: center;
    color: #999;
    line-height: 25px;
  }
  .street_con {
    .dis;
    flex-wrap: wrap;
    width: 1090px;
    .street_list {
      margin-right: 50px;
      // margin-bottom: 8px;
      line-height: 25px;
      color: #222;
      cursor: pointer;
    }
    .street_active {
      color: @color;
    }
  }
}
.supplier_box {
  .supplier_list {
    .dis;
    border-bottom: 1px solid #ececec;
    padding: 50px 0 20px;
    .supplier_icon {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
    .supplier_info {
      cursor: pointer;
      margin: 0 20px;
      width: 170px;
      .info_tit {
        font-size: 18px;
        font-weight: bold;
      }
      .info_address {
        font-size: 14px;
        color: #555;
        padding: 15px 0;
      }
      .info_star {
        .disa;
        color: #999;
        font-size: 14px;
        .star_list {
          margin-right: 5px;
          font-size: 0;
          img {
            width: 17px;
            height: 17px;
            margin-right: 5px;
          }
        }
      }
    }
    .supplier_goods {
      .dis;
      flex-wrap: wrap;
      width: 960px;
      .sgoods_list {
        width: 150px;
        margin-right: 50px;
        margin-bottom: 30px;
        &:nth-child(5n) {
          margin-right: 0;
        }
        .sgoods_img {
          width: 100%;
          height: 150px;
        }
        .sgoods_tit {
          padding-top: 10px;
          .overo;
          text-align: center;
        }
      }
    }
  }
}
</style>
